import React, { Fragment } from 'react'
import { PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";

const MensagemDivergenciaProfessor = ({ divergencia }) => (
    divergencia?.divergenciaDisponibilidade &&
    <li>
        {divergencia.professor.nome_social}
    </li>
);

const MensagemDivergencia = ({ divergencia }) => (
    <Fragment>
        {
            divergencia?.semLocal &&
            <p>
                Sem local para esta aula
            </p>
        }
        {
            divergencia?.semProfessor &&
            <p>
                Sem professor para esta aula
            </p>
        }
        {
            divergencia?.divergenciaServico &&
            <p>
                Sem serviço para esta aula
            </p>
        }
        {
            divergencia?.divergenciaQtdLocal &&
            <p>
                Quantidade de alunos no local da aula
            </p>
        }
        {
            divergencia?.divergenciaQtdAulas &&
            <p>
                Existem mais aulas que ofertas
            </p>
        }
        {
            divergencia?.divergenciaQtdOfertas &&
            <p>
                Existem mais ofertas que aulas
            </p>
        }
        {
            divergencia?.horasDisponiveis &&
            <Fragment>
                Hora Ociosa:
                <ul>
                    <li>1 hora</li>
                </ul>
            </Fragment>
        }
    </Fragment>
);

const PopOverCriticas = ({ id, divergencias = [] }) => {
    const exibirTextoDisponibilidade = divergencias.find((divergencia) => divergencia.divergenciaDisponibilidade === true);
    return (
        <UncontrolledPopover trigger="hover" placement="top" target={`pop${id}`} style={{ width: '360px' }}>
            <PopoverHeader>Divergências</PopoverHeader>
            <PopoverBody>
                {exibirTextoDisponibilidade && 'Disponibilidade do Professor:'}
                <ul>
                    {
                        divergencias.map((divergencia, index) => <MensagemDivergenciaProfessor divergencia={divergencia} key={index} />)
                    }
                </ul>
                {
                    divergencias.length > 0 && <MensagemDivergencia divergencia={divergencias[0]} />
                }
            </PopoverBody>
        </UncontrolledPopover>
    );
}

export default React.memo(PopOverCriticas);
