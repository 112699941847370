import React, { PureComponent, Fragment } from 'react';
import { Card, Row, CardHeader, CardBody, Collapse, Button } from "reactstrap";
import PropTypes from 'prop-types';
import { SelectForm } from '../Basicos';

const CardHoc = (ComponentWrap) => {
    class HOCcomponent extends PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                collapse: this.props.collapse ? false : true,
                periodoLetivo: { value: 5, label: '2024.1' },
                periodosLetivos: [
                    { id: 3, descricao: '2023.1' },
                    { id: 4, descricao: '2023.2' },
                    { id: 5, descricao: '2024.1' },
                    { id: 6, descricao: '2024.2' }
                ],
                selectedSemestre: { value: 2, label: '1º' },
                semestresOptions: [
                    { id: 2, descricao: '1º' },
                    { id: 3, descricao: '2º' },
                    { id: 4, descricao: '3º' },
                    { id: 5, descricao: '4º' },
                    { id: 6, descricao: '5º' },
                    { id: 7, descricao: '6º' },
                    { id: 8, descricao: '7º' },
                    { id: 9, descricao: '8º' }
                ]
            };
        }

        toggleCollapse = () => this.setState((prevState) => ({ collapse: !prevState.collapse }));

        handlePeriodoLetivoChange = (selectedOptionValue) => {
            this.setState({ periodoLetivo: { value: selectedOptionValue, label: this.state.periodosLetivos.map((item) => { if (item.id === selectedOptionValue) return item.descricao }) } });
        };

        handleSemestreChange = (selectedOptionValue) => {
            this.setState({ selectedSemestre: { value: selectedOptionValue, label: this.state.semestresOptions.map((item) => { if (item.id === selectedOptionValue) return item.descricao }) } });
        };

        periodosLetivosOptions = () =>
            this.state.periodosLetivos.map(periodoLetivo => ({ value: periodoLetivo.id, label: periodoLetivo.descricao }));

        semestreOptions = () =>
            this.state.semestresOptions.map(semestre => ({ value: semestre.id, label: semestre.descricao }));

        render() {
            return (
                <Card className={this.props.className} data-title={this.props.title}>
                    {this.props.header && (
                        <CardHeader
                            className="card-subFuncionalidades cursor-ponteiro"
                            onClick={this.toggleCollapse}
                            style={{ lineHeight: (this.props.buttonHeader || this.props.buttonCrud) ? 1.7 : 1.2 }}
                            tag="h5"
                        >
                            {this.props.tituloHeader}

                            {this.props.buttonCrud && (
                                <Fragment>
                                    <Button onClick={this.props.buttonHeaderEditarOnClick}
                                        className="btnHeader float-right">
                                        Editar
                                    </Button>
                                    <Button onClick={this.props.buttonHeaderDeletarOnClick}
                                        className="btnHeader float-right">
                                        Deletar
                                    </Button>
                                </Fragment>
                            )}

                            {this.props.buttonHeader && (
                                <Button onClick={(e) => { e.stopPropagation(); this.props.buttonHeaderOnClick() }}
                                    className="btnHeader float-right">
                                    {this.props.buttonLabel}
                                </Button>
                            )}
                        </CardHeader>
                    )}
                    {this.props.collapse ? (
                        <Collapse isOpen={this.state.collapse}>
                            <CardBody className={'h-100 ' + this.props.classNameBody}>
                                {this.props.tituloHeader === 'Rodízio Manual' ? (
                                    <Fragment>
                                        <SelectForm
                                            name="periodoLetivoSelect"
                                            label="Período Letivo"
                                            placeholder={'Período Letivo'}
                                            value={this.state.periodoLetivo}
                                            options={this.periodosLetivosOptions()}
                                            onChange={(input, value) => this.handlePeriodoLetivoChange(value)}
                                        />
                                        <SelectForm
                                            label="Semestre"
                                            name="SemestreSelect"
                                            placeholder="Semestre"
                                            value={this.state.selectedSemestre}
                                            options={this.semestreOptions()}
                                            onChange={(input, value) => this.handleSemestreChange(value)}
                                        />
                                    </Fragment>
                                ) : null}

                                <Row className='h-100'>
                                    <ComponentWrap
                                        {...this.props}
                                        ref={this.props.innerRef}
                                        collapse={this.state.collapse}
                                        periodoLetivo={this.state.periodoLetivo}
                                        selectedSemestre={this.state.selectedSemestre}

                                    />
                                </Row>
                            </CardBody>
                        </Collapse>
                    ) : (
                        <CardBody className={'h-100 ' + this.props.classNameBody}>
                            <Row className='h-100'>
                                <ComponentWrap {...this.props} ref={this.props.innerRef} collapse={this.state.collapse} />
                            </Row>
                        </CardBody>
                    )}
                </Card>
            );
        }
    }

    HOCcomponent.propTypes = {
        classNavBar: PropTypes.string,
        titulo: PropTypes.string,
        inputChange: PropTypes.func,
        radioChange: PropTypes.func,
        className: PropTypes.string,
        collapse: PropTypes.bool,
        header: PropTypes.bool,
        tituloHeader: PropTypes.string,
        buttonHeader: PropTypes.bool,
        buttonLabel: PropTypes.string,
        buttonCrud: PropTypes.bool,
        buttonHeaderOnClick: PropTypes.func,
        buttonHeaderDeletarOnClick: PropTypes.func,
        buttonHeaderEditarOnClick: PropTypes.func,
    };

    return HOCcomponent;
};

export default CardHoc;