import React from 'react';
import { Col, Row, Modal, ModalBody, Button, } from 'reactstrap';

export default function ModalVisualizar({
    tiposDeAtividades,
    horarios_aulas = null,
    modalVisualizarEstaAberto,
    setModalVisualizarEstaAberto,
    deletarHorario,
    abrirModalAssociar,
    modalCancelarAula,
    abrirModalConteudo
}) {
    const IsecPraticoVivencia = tiposDeAtividades.filter(tp => tp.abreviatura == 'IPRA' || tp.abreviatura == 'VIV').map(tp => tp.id);

    return horarios_aulas && (
        <Modal isOpen={modalVisualizarEstaAberto} className="modalVisualizarHorarioAula">
            <ModalBody>
                <Row className="menu-modal-formal">
                    <Col sm={12} className="align-content-middle mb-2 mt-2">
                        <h5><b>Informações das Aula</b></h5>
                    </Col>
                </Row>
                {
                    horarios_aulas.map((horario_aula, index) => (
                        <Row key={index} className="mt-3">
                            <Col lg={12} className="mb-1">
                                <span className="">Título</span>
                                <div className="form-control-view mb-1">
                                    {horario_aula.cancelada && "(cancelada) - "}
                                    <span className={"spaVisual mb-1"}>{horario_aula?.aula?.sequencia_aula + "-" + horario_aula?.aula?.titulo.toUpperCase()}</span>
                                </div>
                            </Col>
                            <Col sm={12} className="mb-1">
                                <span className="">Tipo de Atividade</span>
                                <textarea className="form-control-view mb-1" readOnly value={horario_aula?.aula?.tipo_de_atividade?.nome.toUpperCase()} />
                            </Col>
                            <Col sm={12} className="mb-1">
                                <span className="">Grupo de Alunos</span>
                                <textarea className="form-control-view mb-1" readOnly value={horario_aula?.grupos_por_aula?.map(element => element.grupo?.descricao.toUpperCase()).join(',')} />
                            </Col>
                            <Col sm={12} className="mb-1">
                                <span className="">Professor</span>
                                <textarea className="form-control-view mb-1" readOnly value={horario_aula?.professores?.map(element => element.nome.toUpperCase()).join(',')} />
                            </Col>
                            <Col sm={12} className="mb-1">
                                <span className="">Local</span>
                                <textarea className="form-control-view mb-1" readOnly value={horario_aula?.local?.nome.toUpperCase()} />
                            </Col>
                            <Col sm={9} className="mb-1">
                                <span className="">Tema</span>
                                <textarea className="form-control-view mb-1" readOnly value={horario_aula?.aula?.tema?.nome} />
                            </Col>
                            <Col sm={3} className="mb-1">
                                <span className="">Semestre</span>
                                <textarea className="form-control-view mb-1" readOnly value={horario_aula?.aula?.semestre?.descricao} />
                            </Col>
                            {
                                IsecPraticoVivencia.includes(horario_aula.aula.tipo_de_atividade_id) &&
                                <Col sm={12} className="mb-1">
                                    <span className="">Serviço</span>
                                    <textarea className="form-control-view mb-1" readOnly value={horario_aula?.servico?.descricao} />
                                </Col>
                            }
                            <Col sm={12} className="mb-1">
                                <span className="">Objetivos de Aprendizagem</span>
                                <textarea className="form-control-view mb-1" readOnly value={horario_aula?.aula?.objetivo_aprendizagem ?? ""} />
                            </Col>
                            <Col sm={12} className="align-content-middle mb-3 p-0">
                                {
                                    horario_aula?.horario_periodo.tipo_de_atividades.prova ?
                                        <Button onClick={() => abrirModalConteudo(horario_aula.id)}
                                            className="float-left btnHeader btn btn-outline-danger"
                                        > conteúdo
                                        </Button> : ""
                                }
                            </Col>
                            <div sm={12} style={{
                                borderBottom: "1px solid var(--cor-border-secundario)",
                                margin: "0rem 1rem",
                                width: "100%"
                            }}>
                            </div>
                        </Row>
                    )
                    )
                }
                <Col sm={12} className="align-content-middle mb-3 p-0 mt-3">
                    <Button className="btn-transparente"
                        onClick={() => setModalVisualizarEstaAberto(false)}
                    > Fechar
                    </Button>
                </Col>
            </ModalBody >
        </Modal >
    );
}