import React, { Fragment, useEffect, useState } from 'react';
import { Col, Button, Row } from "reactstrap";
import { DateForm, InputForm, SelectForm, TableWithVirtualizedRow } from '../Basicos';
import { formatLaravelDate, parseDate } from '../Basicos/funcoes';
import { axiosApi } from '../Basicos/axiosInstances';
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import CardHoc from '../Hoc/cardHoc';
import FileServer from 'file-saver';
import { AiFillFileExcel } from 'react-icons/ai';
import { CardCalendarioPadrao } from './Cards';

import { transformaDataEmString, dataDeUmaSemanaEmString, semanaInicialDoPeriodo, avancarMes, retrocederMes, avancarSemana, retrocederSemana, diaDaSemanaDaDataString, semanaInicialDoPeriodoData } from '../../services/calendarioOferta';
import { ModalVisualizar } from '../Modal';
import ModalConteudo from '../Modal/ModalConteudo';

function CalendarioAvaliacaoSintetica({
    ativar,
    subFuncionalidade: {
        url,
        tipoatividade,
        descricao
    },
}) {
    //Alimenta as opções
    const [periodoLetivoOptions, setPeriodoLetivoOptions] = useState();
    const [semestreOptions, setSemestreOptions] = useState();

    const [valorPeriodoLetivo, setValorPeriodoLetivo] = useState();
    const [valorProfessor, setValorProfessor] = useState();
    const [valorTituloProva, setValorTituloProva] = useState();

    const [semestreIds, setSemestreIds] = useState([]);
    const [dataInicio, setDataInicio] = useState();
    const [dataFim, setDataFim] = useState();

    const [horarioModal, setHorarioModal] = useState({});
    const [modalVisualizarEstaAberto, setModalVisualizarEstaAberto] = useState(false);
    const [semana, setSemana] = useState([]);
    const [periodoLetivo, setPeriodoletivo] = useState({});
    const [temas, setTemas] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [aulas, setAulas] = useState([]);
    const [turma, setTurma] = useState([]);
    const [horariosDefinidos, setHorariosDefinidos] = useState([]);
    const [datasInstitucionais, setDatasInstitucionais] = useState([]);
    const [infoHorarioSelecionado, setInfoHorarioSelecionado] = useState({});
    const [tiposDeAtividades, setTiposDeAtividades] = useState([]);
    const [horariosOfertas, setHorariosOfertas] = useState([]);
    const [ofertasPeriodos, setOfertasPeriodos] = useState({});
    const [locais, setLocais] = useState([]);
    const [modalConteudo, setModalConteudo] = useState(false);
    const [provaId, setProvaId] = useState();

    const [exibirCalendario, setExibirCalendario] = useState(false);

    const getHorariosDoCurso = () =>
        temas && temas.curso && temas.curso.horarios
            ? temas.curso.horarios
            : [];

    const tiposDeAtividadesDoTema = () => {
        return tiposDeAtividades;
    }

    const loadInfosAnalitico = async () => {
        const response = await axiosApi.get(`semana-padrao/prova-analitica`);
        gerarSelectOptions(response.data.filtros.periodos_letivos, response.data.filtros.semestres);
    }

    const gerarSelectOptions = (periodos_letivos, semestres) => {
        setPeriodoLetivoOptions(periodos_letivos && periodos_letivos.map(element => ({ label: element.descricao, value: element.id })));
        setSemestreOptions(semestres)
    }

    useEffect(() => {
        loadInfosAnalitico();
    }, []);

    const pegaDataSelecionada = infoHorario =>
        dataDeUmaSemanaEmString({ semana, diaDaSemana: parseInt(infoHorario.dia_da_semana) });

    const selecionarHorario = infoHorario => {
        const horario_curso = getHorariosDoCurso().filter(horario => horario.id === infoHorario.horario_do_curso_id)[0];

        if (infoHorario.data_selecionada)
            setInfoHorarioSelecionado({
                ...infoHorario,
                dia_da_semana: diaDaSemanaDaDataString(infoHorario.data_selecionada),
                horario_curso,
            });
        else
            setInfoHorarioSelecionado({
                ...infoHorario,
                data_selecionada: pegaDataSelecionada(infoHorario),
                horario_curso,
            });
    }

    const onChangeSelect = (nome, valor) => {
        switch (nome) {
            case 'periodo_letivo_id':
                let periodoLetivo = periodoLetivoOptions.find((element) => element.value === valor);
                setValorPeriodoLetivo(periodoLetivo)
                break;
            case 'semestres_ids':
                setSemestreIds(valor);
                break;
            case 'professor':
                setValorProfessor(valor);
                break;
            case 'titulo_prova':
                setValorTituloProva(valor);
                break;
            default:
                break;
        }
    }

    const onChangeHorario = (intervalo, valores) => {
        if (valores.from != undefined)
            setDataInicio(valores.from)
        if (valores.to != undefined)
            setDataFim(valores.to)
    };
    
    const abrirModalConteudo = (provaId) => {
        setModalConteudo(true);
        setProvaId(provaId);
    }

    console.log(modalConteudo)

    const limparFiltro = () => {
        setExibirCalendario(false);
        setValorPeriodoLetivo(null);
        setValorProfessor('');
        setValorTituloProva('');
        setSemestreIds([]);
        setDataInicio(undefined);
        setDataFim(undefined);
        setTemas([]);
        setTurma(null);
        setAulas([]);
        setLocais([]);
        setGrupos([]);
        setPeriodoletivo(null);
        setOfertasPeriodos([]);
        setHorariosOfertas([]);
        setTiposDeAtividades([]);
    }

    useEffect(() => {
        let horarios = [];
        let datas_institucionais = [];

        if (horariosOfertas.length > 0) {
            horarios = semana?.flatMap(diaDaSemana =>
                horariosOfertas.filter(horarioOferta =>
                    horarioOferta.data?.toString() === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
                )
            );

            datas_institucionais = semana?.flatMap(diaDaSemana =>
                ofertasPeriodos?.calendarioInstitucional?.filter(data_institucional =>
                    data_institucional.data === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
                )
            );

            setInfoHorarioSelecionado({});
            setDatasInstitucionais(datas_institucionais);
            setHorariosDefinidos(horarios);
        }
    }, [semana, horariosOfertas]);

    useEffect(() => {
        //AQUI SETA A SEMANA QUE EXIBIRÁ NO CALENDARIO
        const periodoLetivo = temas.semanapadrao
        if (dataInicio != undefined)
            setSemana(semanaInicialDoPeriodoData({ dataInicio }))
        else
            setSemana(semanaInicialDoPeriodo({ periodoLetivo }))
    }, [periodoLetivo]);

    const mudarSemana = (flag) => {
        if (flag === "avancar")
            setSemana(avancarSemana({ semana, periodoLetivo, dataInicio, dataFim }));
        if (flag === "retroceder") {
            var semanaPadrao = {
                ...periodoLetivo,
                inicio: temas?.semanapadrao.inicio
            }
            setSemana(retrocederSemana({ semana, periodoLetivo: semanaPadrao, dataInicio, dataFim }));
        }
    }

    const mudarMes = (flag) => {
        if (flag === "avancar")
            setSemana(avancarMes({ semana, periodoLetivo, dataInicio, dataFim }));
        if (flag === "retroceder") {
            var semanaPadrao = {
                ...periodoLetivo,
                inicio: temas?.semanapadrao.inicio
            }
            setSemana(retrocederMes({ semana, periodoLetivo: semanaPadrao, dataInicio, dataFim }));
        }
    }

    const abrirModalVisualizar = horario => {
        setHorarioModal(horario);
        setModalVisualizarEstaAberto(true);
    }

    const semestresIdsOptions = () => semestreOptions?.map(grupo => ({ value: grupo.id, label: grupo.descricao }));
    const semestreIdsValue = () => semestresIdsOptions()?.filter(elemento => semestreIds?.includes(elemento.value));

    const filtrar = async () => {
        if (valorPeriodoLetivo == undefined)
            ativar(400, 'Selecione um período letivo!')
        else if (semestreIds.length == 0)
            ativar(400, 'Selecione pelo menos um semestre!')
        else {
            axiosApi({
                method: "GET",
                url: `semana-padrao/filtro-prova-sintetica?nome_professor=${valorProfessor}&periodo_letivo_id=${valorPeriodoLetivo?.value}&semestresIds[]=${semestreIds}&data_inicio=${dataInicio}&data_fim=${dataFim}&titulo_prova=${valorTituloProva}`,
            }).then(resp => {
                const {
                    tema,
                    aulas,
                    local,
                    professores,
                    grupoalunos,
                    periodoLetivo,
                    ofertaPeriodo,
                    horariosofertas,
                    tiposDeAtividades,
                    calendarioInstitucional,
                    turma
                } = resp.data;

                ofertaPeriodo.calendarioInstitucional = calendarioInstitucional;
                setTemas(tema);
                setTurma(turma);
                setAulas(aulas);
                setLocais(local);
                setGrupos(grupoalunos);
                // setProfessores(professores);
                setPeriodoletivo(periodoLetivo);
                setOfertasPeriodos(ofertaPeriodo);
                setHorariosOfertas(horariosofertas);
                setTiposDeAtividades(tiposDeAtividades);
                setExibirCalendario(true);
                // setInfoHorarioSelecionado((prevState) => ({ ...prevState, datainicial }));
            })
        }
    }

    return (
        <Fragment>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex mb-3 header-legenda text-header">
                <h3><strong> {descricao.toUpperCase()} </strong></h3>
            </Col>
            <Col sm={12} md={12}>
                <Row className="pr-5 px-3">
                    <SelectForm
                        label="Período Letivo*"
                        name="periodo_letivo_id"
                        placeholder="Período Letivo"
                        options={periodoLetivoOptions}
                        value={valorPeriodoLetivo ?? ''}
                        onChange={onChangeSelect}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }

                            }}
                    />
                    <SelectForm
                        telaAgenda={true}
                        label="Semestre*"
                        name="semestres_ids"
                        value={semestreIdsValue()}
                        options={semestresIdsOptions()}
                        onChange={onChangeSelect}
                        placeholder="Semestre"
                        isMulti
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4
                                }

                            }}
                    />
                    <InputForm
                        type="text"
                        name="professor"
                        label="Professor"
                        placeholder="Professor"
                        value={valorProfessor ?? ''}
                        onChange={onChangeSelect}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 4,
                                    md: 4,
                                    lg: 6,
                                    xl: 6
                                }

                            }}
                    />
                    <InputForm
                        type="text"
                        name="titulo_prova"
                        label="Título Prova"
                        placeholder="Título Prova"
                        value={valorTituloProva ?? ''}
                        onChange={onChangeSelect}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4
                                }

                            }}
                    />

                    <DateForm
                        name="intervalo_data"
                        label="Tipo de Marcador"
                        style={{ labelClass: "label-centralizado", telaAgenda: false, telaAvaliacao: true }}
                        onChange={(onChangeHorario)}
                        dataInicial={{ "label": "Data Inicial", ...dateInicialFormStyle, diasFuturos: true }}
                        dataFinal={{ "label": "Data Final", ...dateFinalFormStyle, diasFuturos: true }}
                        valorInicial={dataInicio}
                        valorFim={dataFim}
                        onBlur={onChangeHorario}
                        value={{ from: parseDate(dataInicio, formatLaravelDate), to: parseDate(dataFim, formatLaravelDate) }}
                    />
                    <Col className="btn-limpar-filtro-agenda">
                        <Button
                            className="mx-3 py-2 pesquisar-agenda"
                            onClick={filtrar}
                        >Pesquisar</Button>
                    </Col>
                    <Col className="btn-limpar-filtro-agenda">
                        <Button
                            className="mx-3 py-2 pesquisar-agenda"
                            onClick={limparFiltro}
                        >Limpar</Button>
                    </Col>
                </Row>
            </Col>

            <Col xs={13} sm={13} md={13} lg={13} xl={13} className={'pr-5 tabela-avaliacao-analitica'}>
                {
                    exibirCalendario &&
                    <>
                        <CardCalendarioPadrao
                            grupos={grupos}
                            abrirModalVisualizar={abrirModalVisualizar}
                            aulas={aulas}
                            turma={turma}
                            className="h-100"
                            colClassname="calendario-padrao--comDomingoESabado"
                            horariosDefinidos={horariosDefinidos}
                            datasInstitucionais={datasInstitucionais}
                            inicioDaSemana={0}
                            finalDaSemana={6}
                            horarioSelecionado={infoHorarioSelecionado}
                            horariosCurso={getHorariosDoCurso()}
                            tipoAtividades={tiposDeAtividadesDoTema()}
                            selecionarHorario={selecionarHorario}
                            horariosOfertas={horariosOfertas}
                            setHorariosOfertas={setHorariosOfertas}
                            éTipoOferta={true}
                            tipoOferta={{
                                mudarSemana,
                                mudarMes,
                                semana,
                            }}
                            url={url}
                            periodoLetivo={periodoLetivo}
                            semestreId={semestreIds}
                            temas={temas}
                        />

                        <ModalVisualizar
                            horarios_aulas={horarioModal?.horarios_aulas}
                            modalVisualizarEstaAberto={modalVisualizarEstaAberto}
                            setModalVisualizarEstaAberto={setModalVisualizarEstaAberto}
                            grupos={grupos}
                            tiposDeAtividades={tiposDeAtividades}
                            abrirModalConteudo={abrirModalConteudo}
                        />
                        <ModalConteudo
                            isOpen={modalConteudo}
                            id={provaId}
                            setModalConteudo={setModalConteudo}
                        />
                    </>
                }


            </Col>
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        atualizarDadosRemotamente: (params) => dispatch(actionsRedux.atualizarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, params)),
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        exibirCalendario: state.exibirCalendario ?? false
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardHoc(CalendarioAvaliacaoSintetica))

const dateInicialFormStyle = {
    "placeholder": "Data Inicial",
    "style": {
        "labelClass": "label-conteudoInferior",
        "elementoClass": "",
        "col": {
            "sm": 3
        }
    }
}
const dateFinalFormStyle = {
    "placeholder": "Data Final",
    "style": {
        "labelClass": "label-conteudoInferior",
        "elementoClass": "",
        "col": {
            "sm": 3
        }
    }
}