import React, { PureComponent, Fragment } from 'react';
import {
    InputForm,
    SelectForm,
    RadioForm,
    DateForm,
    CheckboxForm,
    CheckboxGroupForm,
    DependenteForm,
    AsyncSelectForm,
    FileExportForm,
    DataUnicForm,
    FileExportFormPDF,
    FileExportFormDOC,
    FileExportFormPPT,
    ReplicarForm,
    RichTextForm
} from './index';
import { axiosApi } from '../Basicos/axiosInstances';
import pick from 'lodash/pick';
import { CardCalendarioDisponibilidade } from '../Conteudo/Cards';

class Estruturas extends PureComponent {
    constructor(props) {
        super(props)
        let inicializar = this.inicializarInputs(this.props.estrutura);
        this.formulario = inicializar.formulario;
        this.state = {
            estrutura: this.props.estrutura,
            invalido: inicializar.invalido,
            incompleto: inicializar.incompleto
        }
    }

    // invalidar = (nome) => {
    //     if(this.formulario){
    //         this.formulario[nome] = undefined;
    //         const verificacao = this.verificarPreechimento(this.state.estrutura, this.formulario);
    //         this.setState({ invalido: verificacao.invalido, incompleto: verificacao.formIncompleto });
    //     }
    // }

    iniciarValorPadrao = (elemento, formulario) => {
        console.log(elemento, formulario)
        switch (elemento.type) {
            case 'select':
            case 'asyncselect':
                if (elemento.valorPadrao !== undefined && elemento.valorPadrao.value !== 0) {
                    formulario[elemento.name] = elemento.isMulti ? elemento.valorPadrao.map(elemento => (elemento.value)) : elemento.valorPadrao.value;
                } else if (elemento.value != undefined) {
                    formulario[elemento.name] = Array.isArray(elemento.value) ? elemento.value[0].value : elemento.value.value;
                }
                // if (elemento.valorPadrao !== undefined) {
                //     formulario[elemento.name] = elemento.isMulti ? elemento.valorPadrao.map(elemento => (elemento.value)) : elemento.valorPadrao.value;
                // } 
                else {
                    formulario[elemento.name] = undefined;
                }
                break;
            case 'checkbox':
                formulario[elemento.name] = (this.props.valorPadrao) ? true : false;
                break;
            case "date":
                if (elemento.dataFinal && elemento.dataFinal.valorPadrao) {
                    formulario[elemento.name] = {
                        from: elemento.dataInicial.valorPadrao.split(' ')[0],
                        to: elemento.dataFinal.valorPadrao.split(' ')[0]
                    }
                } else if (elemento.dataInicial.valorPadrao) {
                    formulario[elemento.name] = elemento.dataInicial.valorPadrao.split(' ')[0];
                }
                break;

            case "dependente":
                formulario[elemento.name] = {
                    estruturas: elemento.subordinados.valoresPadroes !== undefined ? elemento.subordinados.valoresPadroes : [{}],
                    [elemento.select.name]: elemento.select.valorPadrao ? elemento.select.valorPadrao.value : undefined
                };
                break;

            default:
                formulario[elemento.name] = elemento.valorPadrao;
                break;

        }
    }

    inicializarInputs = (estrutura) => {
        let formulario = {};
        let invalido = [];
        let incompleto = false;

        estrutura.forEach((elemento) => {
            this.iniciarValorPadrao(elemento, formulario);
            if (elemento.isRequired && (formulario[elemento.name] === {} || !formulario[elemento.name])) {
                invalido.push(true);
                incompleto = true;
            } else {
                invalido.push(false);
            }
        });

        if (this.props.onChange) {
            this.props.onChange(
                formulario,
                invalido,
                incompleto,
                this.invalidar
            );
        }

        return { formulario, invalido, incompleto };
    }
    verificarPreechimento = (estrutura, formulario) => {
        let formIncompleto = false;
        const invalido = [];

        estrutura.forEach((elemento) => {
            if (elemento.isRequired && elemento.type === "rotaForm" && !formulario[elemento.name].rota_id) {
                invalido.push(true);
                formIncompleto = true;
            } else if (elemento.isRequired && (formulario[elemento.name] === undefined || formulario[elemento.name] === "")) {
                invalido.push(true);
                formIncompleto = true;
            } else if (elemento.isRequired && elemento.dataFinal !== undefined && (formulario[elemento.name].to === undefined || formulario[elemento.name].from === undefined)) {
                invalido.push(true);
                formIncompleto = true;
            } else {
                invalido.push(false);
            }
        });
        return { formIncompleto, invalido };
    }

    atualizarEstrutura = async ({ url, metodo = "get", params }) => {
        try {
            const formularioGeral = { ...this.props.formularioGeral, ...this.formulario };
            const response = await axiosApi({
                method: metodo,
                url: url,
                params: params ? pick(formularioGeral, params) : formularioGeral
            });

            console.log(this.state.estrutura)
            const estrutura = this.state.estrutura.concat(response.data.estrutura);
            for (let i = 0; i < estrutura.length; i++) {
                for (let j = i + 1; j < estrutura.length; j++) {
                    if (estrutura[i].name === estrutura[j].name) {
                        estrutura[i] = { ...estrutura[i], ...estrutura[j] };
                        this.iniciarValorPadrao(estrutura[i], this.formulario);
                        estrutura.splice(j, 1);
                        j--;
                    }
                }
            };

            const verificacao = this.verificarPreechimento(estrutura, this.formulario);
            this.setState({ estrutura, invalido: verificacao.invalido, incompleto: verificacao.formIncompleto });
        } catch (error) {
            // console.log(error);
        }
    }

    changeValues = (nome, valor, indice = undefined) => {
        this.formulario[nome] = valor;
        const verificacao = this.verificarPreechimento(this.state.estrutura, this.formulario);

        if (indice !== undefined && this.state.estrutura[indice].callback) {
            this.atualizarEstrutura(this.state.estrutura[indice].callback);
        } else {
            this.setState({ invalido: verificacao.invalido, incompleto: verificacao.formIncompleto });
        }

        if (this.props.onChange) {
            this.props.onChange(
                this.formulario,
                verificacao.invalido,
                verificacao.formIncompleto,
                this.invalidar
            );
        }
    }

    render() {
        const { estrutura } = this.state;
        return (
            <Fragment>
                {estrutura.map((elemento, indice) => {
                    switch (elemento.type) {
                        case "select":
                            return <SelectForm
                                key={elemento.label + indice}
                                submit={this.props.submit}
                                onChange={this.changeValues}
                                indice={indice}
                                invalido={this.state.invalido[indice]}
                                atualizarEstrutura={this.atualizarEstrutura}
                                {...elemento}
                            />
                        case "asyncselect":
                            return <AsyncSelectForm
                                key={elemento.label + indice}
                                submit={this.props.submit}
                                onChange={this.changeValues}
                                indice={indice}
                                invalido={this.state.invalido[indice]}
                                valoresAtuais={this.formulario}
                                atualizarEstrutura={this.atualizarEstrutura}
                                {...elemento}
                            />
                        case "radio":
                            return <RadioForm
                                key={elemento.label + indice}
                                submit={this.props.submit}
                                onChange={this.changeValues}
                                indice={indice}
                                invalido={this.state.invalido[indice]}
                                {...elemento}
                            />
                        case "date":
                            return <DateForm
                                key={indice}
                                submit={this.props.submit}
                                invalido={this.state.invalido[indice]}
                                onChange={this.changeValues}
                                {...elemento}
                            />
                        case "date-unic":
                            return <DataUnicForm
                                key={indice}
                                submit={this.props.submit}
                                invalido={this.state.invalido[indice]}
                                onChange={this.changeValues}
                                {...elemento}
                            />
                        case "checkbox":
                            return <CheckboxForm
                                key={elemento.label + indice}
                                onChange={this.changeValues}
                                indice={indice}
                                {...elemento}
                            />
                        case "checkboxGroup":
                        case "checkboxGroup2":
                            return <CheckboxGroupForm
                                key={elemento.label + indice}
                                onChange={this.changeValues}
                                indice={indice}
                                {...elemento}
                            />
                        case "dependente":
                            return <DependenteForm key={elemento.name + indice}
                                onChange={this.changeValues}
                                submit={this.props.submit}
                                invalido={this.state.invalido[indice]}
                                valorAtual={this.formulario[elemento.name]}
                                formularioGeral={this.props.formularioGeral}
                                {...elemento}
                            />
                        case "replicarForm":
                            return <ReplicarForm key={elemento.name + indice}
                                onChange={this.changeValues}
                                submit={this.props.submit}
                                invalido={this.state.invalido[indice]}
                                valorAtual={this.formulario[elemento.name]}
                                formularioGeral={this.props.formularioGeral}
                                {...elemento}
                            />
                        case "fileExportForm":
                            return <FileExportForm
                                key={elemento.name + indice}
                                onChange={this.changeValues}
                                valorAtual={this.formulario[elemento.name]}
                                {...elemento}
                            />
                        case "fileExportFormPDF":
                            return <FileExportFormPDF
                                key={elemento.name + indice}
                                onChange={this.changeValues}
                                valorAtual={this.formulario[elemento.name]}
                                {...elemento}
                            />
                        case "fileExportFormDOC":
                            return <FileExportFormDOC
                                key={elemento.name + indice}
                                onChange={this.changeValues}
                                valorAtual={this.formulario[elemento.name]}
                                {...elemento}
                            />
                        case "fileExportFormPPT":
                            return <FileExportFormPPT
                                key={elemento.name + indice}
                                onChange={this.changeValues}
                                valorAtual={this.formulario[elemento.name]}
                                {...elemento}
                            />
                        case "RichTextForm":
                            return <RichTextForm
                                key={elemento.label + indice}
                                submit={this.props.submit}
                                invalido={this.state.invalido[indice]}
                                indice={indice}
                                onChange={this.changeValues}
                                valorPadrao={this.formulario[elemento.name]}
                                {...elemento}
                            />
                        case "calendario-disponibilidade":
                            return <CardCalendarioDisponibilidade
                                key={elemento.name + indice}
                                {...elemento}
                            />
                        default:
                            return (
                                <InputForm
                                    key={elemento.label + indice}
                                    submit={this.props.submit}
                                    mask={
                                        elemento.type === 'tel'
                                            ? ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                                            : elemento.type === 'cnpj'
                                                ? [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                                                : ""
                                    }
                                    invalido={this.state.invalido[indice]}
                                    indice={indice}
                                    onChange={this.changeValues}
                                    valoresAtuais={this.formulario}
                                    {...elemento}
                                />
                            );

                    }
                })}
            </Fragment>
        )
    }
}

export default Estruturas
