import React from 'react'
import { Row, Col } from 'reactstrap';
import { Redirect, Switch, Route } from "react-router-dom";
import { MenuDasFuncionalidades } from "../Menu/index";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { CardCrud } from '../Conteudo/Cards/index';
import { convertToSlug } from '../Basicos/funcoes';
import SubgrupoAlunos from '../Conteudo/SubgruposAlunos';
import AssociadorDeGrupos from '../Conteudo/AssociadorDeGrupos';


function Alunos({ alunos, }) {
    return (
        <Row noGutters={true}>
            <MenuDasFuncionalidades
                funcionalidade={"alunos"}
                classNavBar="navbar-consultas"
                titulo="Alunos"
            />
            <Col sm={12} md={12} className="col-conteudo">
                <Switch>
                    {alunos.map((subFuncionalidade, indice) => {
                        switch (convertToSlug(subFuncionalidade.descricao)) {
                            case "grupos":
                                return (
                                    <Route
                                        key={subFuncionalidade.url}
                                        path={subFuncionalidade.url}
                                        render={(props) => <AssociadorDeGrupos
                                            {...props}
                                            subFuncionalidade={subFuncionalidade}
                                            indiceSubFuncionalidade={indice}
                                            funcionalidade={"alunos"}
                                        />
                                        }
                                    />
                                );
                            case "subgrupos":
                                return (
                                    <Route
                                        key={subFuncionalidade.url}
                                        path={subFuncionalidade.url}
                                        render={(props) => <SubgrupoAlunos
                                            {...props}
                                            subFuncionalidade={subFuncionalidade}
                                            indiceSubFuncionalidade={indice}
                                            funcionalidade={"subgrupos"}
                                        />
                                        }
                                    />
                                );

                            default:
                                return (
                                    <Route
                                        key={subFuncionalidade.url}
                                        path={subFuncionalidade.url}
                                        render={(props) => <CardCrud
                                            {...props}
                                            subFuncionalidade={subFuncionalidade}
                                            indiceSubFuncionalidade={indice}
                                            funcionalidade={"alunos"}
                                        />
                                        }
                                    />
                                );
                        }
                    })}
                    <Route render={() => <Redirect to="/" />} />
                </Switch>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
    }
}

const mapStateToProps = (state) => {
    return {
        alunos: state.subFuncionalidades.alunos,
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editarFunc: state.subFuncionalidades.editar,
        tipoAcao: state.subFuncionalidades.tipoAcao,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alunos)