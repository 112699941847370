import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Col, Label, FormGroup, FormFeedback, Input, Form, Row } from "reactstrap";
import PropTypes from 'prop-types';
import uploadIcon from "../../images/ic_doc.png";
import imagem from "../../images/doc.png";
import MenuTable from '../../Menu/menuTable';
import { TiDocument } from 'react-icons/ti';
import { axiosApi } from '../axiosInstances';
import FileServer from 'file-saver';

function FileExportFormDOC({
    accept,
    placeholder,
    style,
    label,
    preview,
    valorAtual,
    onChange,
    name,
    limiteDeArquivos,
    minSize,
    maxSize
}) {
    const [files, setFiles] = useState(valorAtual || []);

    useEffect(() => {
        setFiles(valorAtual || []);
    }, [valorAtual]);

    const onDrop = (acceptedFiles) => {
        let quantidadeRestante = limiteDeArquivos - files.length;
        const newFiles = [...files, ...acceptedFiles.slice(0, quantidadeRestante).map(file => ({
            file,
            dataCadastro: new Date().toLocaleDateString(),
            descricaoDocumento: '',
            observacao: '',
            status: 'ativo'
        }))];
        setFiles(newFiles);
        onChange(name, newFiles);
    };

    const isDOC = (file) => file?.file?.name?.toLowerCase().includes('doc');

    const { rejectedFiles, getRootProps, getInputProps } = useDropzone({
        accept: accept,
        maxSize: maxSize,
        minSize: minSize,
        onDrop
    });

    const handleInputChange = (indice, field, value) => {
        const newFiles = [...files];
        newFiles[indice][field] = value;
        setFiles(newFiles);
        onChange(name, newFiles);
    };

    const handleDownload = async (filename) => {
        try {
            await axiosApi.get(`/download/${filename}`, {
                responseType: 'blob',
            }).then((res) => {
                const fileName = `${filename}`;
                const file = new File([res.data], fileName, { type: `${res?.data?.Blob?.type};charset=utf-8` });
                FileServer.saveAs(file);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const aceitos = files.map((doc, indice) => {
        const removerOnClick = () => {
            const auxFiles = files.slice();
            auxFiles.splice(indice, 1);
            setFiles(auxFiles);
            onChange(name, auxFiles);
        };

        // Remove the prefix from the file path
        const filePath = doc.file.path;
        const prefix = "storage/unichristus/documentos_instituicoes/";

        const fileName = filePath.startsWith(prefix) ? filePath.slice(prefix.length) : filePath;

        return (
            <div key={indice} style={{
                display: "flex",
                flexDirection: "column",
                margin: "8px",
                border: "1px solid #ccc",
                padding: "8px"
            }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {(preview && isDOC(doc)) ? (
                        <img src={imagem} className="pdf-file" alt="Document Preview" />
                    ) : (
                        <TiDocument className="menuIcons pdf-file" />
                    )}
                    <span style={{ flex: 1, margin: "0 10px 0 10px", lineHeight: "60px" }}>
                        {/* <a href={`/download/${fileName}`} download>{fileName}</a> */}
                        <a style={{ color: "blue", cursor: 'pointer' }} onClick={() => handleDownload(fileName)}>{fileName}</a>
                    </span>
                    <MenuTable
                        alinharDireita
                        iconRemover
                        removerOnClick={removerOnClick}
                    />
                </div>
                <Form>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for={`dataCadastro-${indice}`}>Data do Cadastro</Label>
                                <Input
                                    type="text"
                                    name={`dataCadastro-${indice}`}
                                    id={`dataCadastro-${indice}`}
                                    value={doc.dataCadastro}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for={`status-${indice}`}>Status</Label>
                                <Input
                                    type="select"
                                    name={`status-${indice}`}
                                    id={`status-${indice}`}
                                    value={doc.status}
                                    onChange={(e) => handleInputChange(indice, 'status', e.target.value)}
                                >
                                    <option value="ativo">Ativo</option>
                                    <option value="inativo">Inativo</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label for={`descricaoDocumento-${indice}`}>Descrição do Documento</Label>
                        <Input
                            type="text"
                            name={`descricaoDocumento-${indice}`}
                            id={`descricaoDocumento-${indice}`}
                            value={doc.descricaoDocumento}
                            onChange={(e) => handleInputChange(indice, 'descricaoDocumento', e.target.value)}
                            required
                        />
                        <FormFeedback>Campo obrigatório</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for={`observacao-${indice}`}>Observação</Label>
                        <Input
                            type="text"
                            name={`observacao-${indice}`}
                            id={`observacao-${indice}`}
                            value={doc.observacao}
                            onChange={(e) => handleInputChange(indice, 'observacao', e.target.value)}
                            required
                        />
                        <FormFeedback>Campo obrigatório</FormFeedback>
                    </FormGroup>
                </Form>
            </div>
        );
    });

    const rejeitados = rejectedFiles.map((file, indice) => (
        <FormFeedback style={{ display: "block" }} key={indice}>
            {`Arquivo ${file.name} é inválido. Verifique o tamanho ou tipo!`}
        </FormFeedback>
    ));

    return (
        <Col {...style.col} className="form-col">
            <FormGroup className="form-group">
                {label && (
                    <Label className={style.labelClass + " label"}>
                        {label + ":"}
                    </Label>
                )}
                {aceitos}
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <img src={uploadIcon} width="150" height="150" alt="Upload Icon" />
                    <p className="m-0">Arraste e solte os seus documentos ou clique para selecionar o arquivo para upload</p>
                    {placeholder && <em>{placeholder}</em>}
                </div>
                {rejeitados}
            </FormGroup>
        </Col>
    );
}

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)
};

FileExportFormDOC.defaultProps = {
    mask: undefined,
    preview: true,
    valorAtual: [],
    limiteDeArquivos: 1000,
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "",
        col: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }
    }
};

FileExportFormDOC.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.shape(styleShape)
};

export default FileExportFormDOC;
